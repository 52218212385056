import { createContext, useReducer } from "react";
export const LoginErrorContext = createContext();
export const loginErrorReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOGIN_ERROR':
            return {
                loginError: action.payload
            }
        case 'CREATE_LOGIN_ERROR':
            return {
                loginError: true
            }
        case 'DELETE_LOGIN_ERROR':
            return {
                loginError: null
            }
        default:
            return state
    };
};
export const LoginErrorContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(loginErrorReducer, {
        loginError: null
    });
    return (
        <LoginErrorContext.Provider value={{ ...state, dispatch }}>
            {children}
        </LoginErrorContext.Provider>
    );
};